import React, { useState, useEffect } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  FormControl,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  InputAdornment,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PheonixCheckbox } from "pheonixui";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TEXT_MESSAGES } from "../../const";
import CancelIcon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { useQuery, useMutation } from "@apollo/client";
import { ApolloError } from "@apollo/client";
import SnackBar from "../../Components/PheonixSnackBar";
import { REMOVE_FROM_CART } from "../../graphql/mutation";
import { PaymentInterface } from "./ProductTableprops";

interface PaymentItem {
  id: number;
  productDetails: string;
  invoiceNumber?: string;
  hsnNumber: string;
  rate: string;
  tax?: string;
  amount: string;
  isUnpaidInvoice?: boolean;
}

interface UnpaidInvoiceItem {
  id: number;
  date: string;
  invoiceNumber?: string;
  hsnNumber: string;
  rate: string;
  tax: string;
  invoiceAmount: string;
  productDetails: string;
}

const PaymentComponent: React.FC<PaymentInterface> = ({
  rows,
  orderDate,
  setOrderDate,
  formErrors,
  setFormErrors,
  totalAmount,
  setTotalAmount,
  setRows,
}) => {
  const theme = useTheme();

  const [wantToPayPending, setWantToPayPending] = useState<boolean>(false);

  const [totalTax, setTotalTax] = useState<number>(0);
  const [promotionCode, setPromotionCode] = useState<string>("");
  const [removeFromCart] = useMutation(REMOVE_FROM_CART);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [succMessage, setSuccMessage] = useState("");

  const headers = [
    "Product Details",
    "Product Plan",
    "Quantity",
    "HSN Number",
    "Rate",
    "Amount",
  ];

  const isRowEmpty = (row: any) => {
    return (
      !row["Product Details"] &&
      !row["Product Plan"] &&
      !row["Quantity"] &&
      !row["HSN Number"] &&
      !row["Rate"] &&
      !row["Amount"]
    );
  };

  const displayRows = rows.filter((row) => !isRowEmpty(row));

  const calculateTotalAmount = () => {
    return displayRows.reduce(
      (sum, row) => sum + (parseFloat(row["Amount"]) || 0),
      0
    );
  };

  const unpaidInvoiceData = {
    headers: ["Date", "HSN Number", "Rate", "Tax", "Invoice Amount"],
    rows: [
      {
        id: 2,
        date: "15/02/2025",
        invoiceNumber: "IN-7484123",
        hsnNumber: "K49448",
        rate: "25,000",
        tax: "1050",
        invoiceAmount: "26,050",
        productDetails: "Unpaid Invoice 1",
      },
      {
        id: 3,
        date: "28/01/2025",
        invoiceNumber: "IN-7483998",
        hsnNumber: "K49448",
        rate: "30,000",
        tax: "1260",
        invoiceAmount: "31,260",
        productDetails: "Unpaid Invoice 2",
      },
    ] as UnpaidInvoiceItem[],
  };
  const overallPaymentHeaders = [
    "Product Details",
    "Invoice Number",
    "HSN Number",
    "Rate *",
    "Amount *",
  ];

  const transformedRows = displayRows.map((row, index) => ({
    id: row["_productId"] || index + 1,
    productDetails: row["Product Details"] || "",
    hsnNumber: row["HSN Number"] || "",
    rate: row["Rate"]?.toString() || "0",
    amount: row["Amount"]?.toString() || "0",
  }));

  const [regularPaymentItems, setRegularPaymentItems] =
    useState<PaymentItem[]>(transformedRows);

  const [overallPaymentItems, setOverallPaymentItems] = useState<PaymentItem[]>(
    [...regularPaymentItems]
  );

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setWantToPayPending(isChecked);

    if (isChecked) {
      const unpaidInvoiceItems: PaymentItem[] = unpaidInvoiceData.rows.map(
        (invoice) => ({
          id: invoice.id,
          productDetails: invoice.productDetails,
          hsnNumber: invoice.hsnNumber,
          rate: invoice.rate,
          tax: invoice.tax,
          amount: invoice.invoiceAmount,
          isUnpaidInvoice: true,
        })
      );

      setOverallPaymentItems([...regularPaymentItems, ...unpaidInvoiceItems]);
    } else {
      const filteredItems = overallPaymentItems.filter(
        (item) => !item.isUnpaidInvoice
      );
      setOverallPaymentItems(filteredItems);
    }
  };

  const handleDateChange = (newDate: any) => {
    setOrderDate(newDate);
    if (formErrors["orderDate"]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        orderDate: "",
      }));
    }
  };

  const handlePromotionCode = async () => {
   
  };

  const applyPromotionCode = () => {};

  const handleRemoveItem = async (id: number) => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      try {
        const { data } = await removeFromCart({
          variables: { customerId: userId, productId: id.toString() },
        });
        if (data?.removeFromCart?.success) {
          setSnackbarOpen(true);
          setSnackbarMessage(data.removeFromCart.message);
          const removedItem = overallPaymentItems.find(
            (item) => item.id === id
          );

          const updatedItems = overallPaymentItems.filter(
            (item) => item.id !== id
          );
          setOverallPaymentItems(updatedItems);

          if (removedItem && !removedItem.isUnpaidInvoice) {
            const updatedRegularItems = regularPaymentItems.filter(
              (item) => item.id !== id
            );
            setRegularPaymentItems(updatedRegularItems);
          }
          const updatedRows = rows.filter((row) => row._productId !== id);
          setRows(updatedRows);
          setSnackbarSeverity("success");
          setTimeout(() => {
            setSnackbarOpen(false);
            setSnackbarMessage("");
          }, 3000);
        }
      } catch (error: any) {
        console.error(error);
        setSnackbarOpen(true);
        setSnackbarMessage(error);
        setSnackbarSeverity("error");
        setTimeout(() => {
          setSnackbarOpen(false);
          setSnackbarMessage("");
          setSnackbarSeverity("success");
        }, 3000);
      }
    }
  };

  useEffect(() => {
    let total = 0;
    let taxTotal = 0;

    overallPaymentItems.forEach((item) => {
      const amount = parseFloat(item.amount.replace(/,/g, ""));
      const tax = item.tax ? parseFloat(item.tax.replace(/,/g, "")) : 0;
      total += amount;
      taxTotal += tax;
    });

    setTotalAmount(total);
    setTotalTax(taxTotal);
  }, [overallPaymentItems]);

  useEffect(() => {
    const filteredRows = rows.filter((row) => !isRowEmpty(row));

    const newTransformedRows = filteredRows.map((row, index) => ({
      id: row["_productId"] || index + 1,
      productDetails: row["Product Details"] || "",
      hsnNumber: row["HSN Number"] || "",
      rate: row["Rate"]?.toString() || "0",
      amount: row["Amount"]?.toString() || "0",
    }));

    setRegularPaymentItems(newTransformedRows);

    if (!wantToPayPending) {
      setOverallPaymentItems(newTransformedRows);
    } else {
      const unpaidItems = overallPaymentItems.filter(
        (item) => item.isUnpaidInvoice
      );
      setOverallPaymentItems([...newTransformedRows, ...unpaidItems]);
    }
  }, [rows]);

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(amount);
  };
  const cgst = totalTax / 2;
  const sgst = totalTax / 2;

  const filteredOverallPaymentItems = overallPaymentItems.filter(
    (item) => item.productDetails.trim() !== "" || item.isUnpaidInvoice
  );

  return (
    <div
      style={{
        backgroundColor: "white",
        overflowY: "auto",
        minHeight: "auto",
        maxHeight: "52vh",
        padding: "10px 20px 10px 20px",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Roboto",
            color: "#181D8C",
            fontWeight: 600,
            textAlign: "left",
            lineHeight: "24px",
            marginBottom: "10px",
          }}
        >
          {TEXT_MESSAGES.CART_PAYMENT}
        </Typography>
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            maxWidth: "300px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={
                <>
                  <span>Order Date *</span>
                </>
              }
              format="DD/MM/YYYY"
              disablePast
              value={orderDate}
              onChange={handleDateChange}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                  size: "small",
                  error: Boolean(formErrors["orderDate"]),
                  helperText: formErrors["orderDate"] || "",
                  sx: {
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  },
                },
              }}
            />
          </LocalizationProvider>
        </FormControl>

        {displayRows.length > 0 && (
          <div>
            <Card style={{ width: "105%" }}>
              <CardContent
                style={{ backgroundColor: "#EAEBFF", height: "3px" }}
              >
                <div style={{ marginTop: "-10px" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      color: "#2C2B2B",
                      fontWeight: 600,
                      textAlign: "left",
                      lineHeight: "100%",
                    }}
                  >
                    {TEXT_MESSAGES.PRDT_TXT}
                  </Typography>
                </div>
              </CardContent>
              <div>
                <TableContainer>
                  <Table style={{ border: "none" }}>
                    <TableHead>
                      <TableRow style={{ border: "none" }}>
                        {headers.map((header, index) => (
                          <TableCell
                            key={index}
                            style={{
                              borderBottom: "none",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              color: "#2C2B2B",
                              fontWeight: 500,
                              textAlign: "left",
                              lineHeight: "100%",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: "none" }}>
                      {displayRows.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          style={{ backgroundColor: "#F8F8FF", border: "none" }}
                        >
                          <TableCell
                            style={{
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#181D8C",
                              fontWeight: 400,
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {row["Product Details"]}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#181D8C",
                              fontWeight: 400,
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {row["Product Plan"]}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#181D8C",
                              fontWeight: 400,
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {row["Quantity"]}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#181D8C",
                              fontWeight: 400,
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {row["HSN Number"]}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#181D8C",
                              fontWeight: 400,
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {row["Rate"]}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "16px",
                              fontFamily: "Inter",
                              color: "#181D8C",
                              fontWeight: 400,
                              textAlign: "left",
                              lineHeight: "24px",
                            }}
                          >
                            {row["Amount"]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableRow
                      style={{ backgroundColor: "#E6E6FA", border: "none" }}
                    >
                      <TableCell colSpan={headers.length - 2}></TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        Total Amount
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        ₹ {formatCurrency(calculateTotalAmount())}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </div>
        )}

        {wantToPayPending && (
          <div>
            <Card style={{ width: "125%" }}>
              <CardContent
                style={{ backgroundColor: "#EAEBFF", height: "3px" }}
              >
                <div style={{ marginTop: "-10px" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      color: "#2C2B2B",
                      fontWeight: 600,
                      textAlign: "left",
                      lineHeight: "100%",
                    }}
                  >
                    {TEXT_MESSAGES.UNPAID_INVOICE}
                  </Typography>
                </div>
              </CardContent>
              <div>
                <TableContainer>
                  <Table style={{ border: "none" }}>
                    <TableHead>
                      <TableRow style={{ border: "none" }}>
                        {unpaidInvoiceData.headers.map((header, index) => (
                          <TableCell
                            key={index}
                            style={{
                              borderBottom: "none",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              color: "#2C2B2B",
                              fontWeight: 500,
                              textAlign: "left",
                              lineHeight: "100%",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: "none" }}>
                      {unpaidInvoiceData.rows.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          style={{
                            backgroundColor: "#F8F8FF",
                            border: "none",
                            fontSize: "14px",
                            fontFamily: "Inter",
                            color: "#2C2B2B",
                            fontWeight: 500,
                            textAlign: "left",
                            lineHeight: "100%",
                          }}
                        >
                          {Object.values(row)
                            .slice(1, 7)
                            .map((value, cellIndex) => (
                              <TableCell
                                key={cellIndex}
                                style={{ borderBottom: "none" }}
                              >
                                {value}
                              </TableCell>
                            ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Card>
          </div>
        )}

        {filteredOverallPaymentItems.length > 0 && (
          <div>
            <Card style={{ width: "114%" }}>
              <CardContent
                style={{ backgroundColor: "#EAEBFF", height: "3px" }}
              >
                <div style={{ marginTop: "-10px" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Inter",
                      color: "#2C2B2B",
                      fontWeight: 600,
                      textAlign: "left",
                      lineHeight: "100%",
                    }}
                  >
                    {TEXT_MESSAGES.OVERALL_PAYMENT_TEXT}
                  </Typography>
                </div>
              </CardContent>
              <div>
                <TableContainer>
                  <Table style={{ border: "none" }}>
                    <TableHead>
                      <TableRow style={{ border: "none" }}>
                        <TableCell
                          style={{ borderBottom: "none", width: "30px" }}
                        ></TableCell>
                        {overallPaymentHeaders.map((header, index) => (
                          <TableCell
                            key={index}
                            style={{
                              borderBottom: "none",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              color: "#323232",
                              fontWeight: 500,
                              textAlign: "left",
                              lineHeight: "100%",
                            }}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ border: "none" }}>
                      {filteredOverallPaymentItems.map((item, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          style={{
                            backgroundColor: "white",
                            border: "none",
                          }}
                        >
                          <TableCell style={{ borderBottom: "none" }}>
                            <IconButton
                              size="small"
                              onClick={() => handleRemoveItem(item.id)}
                              style={{ color: "#181D8C" }}
                            >
                              <RemoveCircleOutlinedIcon />
                            </IconButton>
                          </TableCell>

                          <TableCell
                            style={{
                              borderBottom: "none",
                              color: "#181D8C",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {item.productDetails}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                              color: "#181D8C",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {item.invoiceNumber ? item.invoiceNumber : "NA"}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                              color: "#181D8C",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            {item.hsnNumber ? item.hsnNumber : "NA"}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                              color: "#181D8C",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            ₹ {item.rate}
                          </TableCell>
                          <TableCell
                            style={{
                              borderBottom: "none",
                              color: "#181D8C",
                              fontFamily: "Inter",
                              fontWeight: 400,
                              fontSize: "16px",
                              lineHeight: "24px",
                            }}
                          >
                            ₹ {item.amount}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                    backgroundColor: "#E6E6FA",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{ 
                        borderRadius: "4px",
                      }}
                    >
                      <TextField
                        placeholder="Promotion Code"
                        variant="outlined"
                        style={{ width: "270px" }}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button
                                onClick={(event) => {
                                  handlePromotionCode();
                                }}
                                variant="contained"
                                size="small"
                                sx={{
                                  backgroundColor: "#181D8C",
                                  color: "white",
                                  fontWeight: "bold",
                                  textTransform:'none',
                                  "&:hover": {
                                    backgroundColor: "#0e114d",
                                  },
                                  height: "39px",
                                  borderRadius: "4px",
                                  minWidth: "80px",
                                  marginRight: "-5px",
                                }}
                              >
                               {TEXT_MESSAGES.APPLY_TEXT}
                              </Button>
                            </InputAdornment>
                          ),
                          sx: {
                            backgroundColor: "white",
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            paddingRight: "4px",
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#323232",
                        fontFamily: "Inter",
                        fontWeight: 700,
                        fontSize: "17px",
                        lineHeight: "100%",
                        mr: 1,
                      }}
                    >
                      {TEXT_MESSAGES.TOTAL_AMOUNT} :
                    </Typography>
                    <Typography
                      sx={{
                        color: "#181D8C",
                        fontFamily: "Inter",
                        fontWeight: 700,
                        fontSize: "17px",
                        lineHeight: "24px",
                      }}
                    >
                      ₹ {formatCurrency(totalAmount)}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Card>
          </div>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "75%",
            mt: 2,
          }}
        >
          <Box
            sx={{
              width: "45%",
            }}
          >
            <Table style={{ border: "none", width: "70%" }}>
              <TableBody>
                <TableRow style={{ border: "none" }}>
                  <TableCell
                    colSpan={2}
                    style={{ borderBottom: "none", textAlign: "right" }}
                  >
                    <Typography
                      sx={{
                        color: "#181D8C",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                      }}
                    >
                      {TEXT_MESSAGES.TOTAL_AMOUNT} : ₹{" "}
                      {formatCurrency(totalAmount)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Box>
      </div>
      <SnackBar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
        vertical="top"
        horizontal="right"
        customAction={
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default PaymentComponent;
