import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  Button,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GET_PRODUCTS_BY_TYPE } from "../graphql/query";
import { ADD_TO_CART } from "../graphql/mutation";
import { useQuery, useMutation } from "@apollo/client";
import { TEXT_MESSAGES } from "../const";
import PheonixSnackBar from "../Components/PheonixSnackBar";
interface Product {
  id: string;
  type: string;
  name: string;
  hsnCode: string;
  description: string;
  benefit: string;
  url: string;
  image: string;
  module: JSON;
  remarks: string;
  status: boolean;
  plans: {
    name: string;
    features: string[];
    pricing: {
      currency: string;
      tld: string;
      rate: number;
      quarterly: number;
      semiAnnually: number;
      biAnnually: number;
      annually: number;
      triAnnually: number;
    };
  };
}
const ProductGridView: React.FC = () => {
  const navigate = useNavigate();
  const handleViewProduct = (product: Product) => {
    navigate("/product", { state: { breadcrumbText: "Detail View", product } });
  };
  const handleViewService = (service: Product) => {
    navigate("/service", {
      state: { breadcrumbText: "Detail View", service },
    });
  };
  const customerId = localStorage.getItem("userId");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "error"
  );
  const [succMessage, setSuccMessage] = useState("");
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  const [addToCart, { loading, error, data }] = useMutation(ADD_TO_CART);
  const handleAddtocart = async (productId: String) => {
    try {
      const response = await addToCart({
        variables: { customerId, productId },
      });

      const { success, message } = response.data.addToCart;
      if (success) {
        setSnackbarSeverity("success");
        setSuccMessage(message);
      } else {
        setSnackbarSeverity("error");
        setSuccMessage(message);
      }
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };

  const handleBuyNow = async (productId: String) => {
    try {
      const response = await addToCart({
        variables: { customerId, productId },
      });

      const { success, message } = response.data.addToCart;
      if (success) {
        setSnackbarOpen(true);
        setSuccMessage(message);
        setSnackbarSeverity("success");
        setTimeout(() => {
          navigate("/profile", {
            state: {
              step: 2,
            },
          });
        }, 3000);
      } else {
        setSnackbarSeverity("error");
        setSuccMessage(message);
      }

      setTimeout(() => {
        setSnackbarOpen(false);
      }, 3000);
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };
  const { data: productsdata, loading: productsLoading } = useQuery<{
    getProductsByType: Product[];
  }>(GET_PRODUCTS_BY_TYPE, {
    variables: { type: "Product" },
  });

  const { data: servicesdata, loading: servicesLoading } = useQuery<{
    getProductsByType: Product[];
  }>(GET_PRODUCTS_BY_TYPE, {
    variables: { type: "Services" },
  });
  const filteredProducts = productsdata?.getProductsByType.filter(
    (product) => product.status === true
  ) || [];
  
  const filteredServices = servicesdata?.getProductsByType.filter(
    (service) => service.status === true
  ) || [];
  return (
    <>
      <h1
        style={{
          fontSize: "22px",
          fontWeight: 700,
          color: "#181D8C",
          marginLeft: "20px",
        }}
      >
        {TEXT_MESSAGES.PRODORSERVICE}
      </h1>

      <Paper
        elevation={3}
        style={{
          margin: "0px",
          padding: "20px",
          maxHeight: "70vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <h2
          style={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#181D8C",
            marginLeft: "10px",
          }}
        >
          {TEXT_MESSAGES.ALLPROD}
        </h2>
        <Grid container spacing={2} sx={{ marginLeft: "15px" }}>
          {filteredProducts.map((product: Product) => (
            <Grid item xs={12} sm={4} key={product.id}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  height: "215px",
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow: "2px 2px 10px 0px #6A5FBE26;",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {product.image && (
                    <CardMedia
                      component="img"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "8px",
                      }}
                      image={product.image}
                      alt={product.name}
                    />
                  )}
                  <Typography
                    component="div"
                    style={{
                      fontWeight: 700,
                      fontSize: "16px",
                      color: "#181D8C",
                    }}
                  >
                    {product.name}
                  </Typography>
                </div>

                <CardContent
                  sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
                >
                  <Box
                    sx={{
                      marginTop: "auto",
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#e7e9ff",
                        color: "#181D8C",
                        width: "173px",
                        fontSize: "12px",
                        height: "22px",
                        textTransform: "none",
                      }}
                      onClick={() =>
                        product.url && window.open(product.url, "_blank")
                      }
                    >
                      {TEXT_MESSAGES.TAKETOSITE}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#7A80FF",
                        color: "#ffffff",
                        fontSize: "12px",
                        width: "100px",
                        height: "22px",
                        textTransform: "none",
                      }}
                      onClick={() => handleViewProduct(product)}
                    >
                      {TEXT_MESSAGES.DETAILVIEW}
                    </Button>
                  </Box>
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "inter",
                      color: "#000000",
                      lineHeight: "18px",
                      maxHeight: "120px",
                      overflow: "auto",
                      paddingRight: "4px",
                    }}
                  >
                    {product.description}
                  </Typography>

                  <Box
                    sx={{
                      marginTop: "auto",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#e7e9ff",
                        color: "#181D8C",
                        width: "173px",
                        fontSize: "12px",
                        height: "22px",
                        textTransform: "none",
                      }}
                      onClick={() => handleAddtocart(product.id)}
                    >
                      {TEXT_MESSAGES.ADDTOCART}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#7A80FF",
                        color: "#ffffff",
                        fontSize: "12px",
                        width: "100px",
                        height: "22px",
                        textTransform: "none",
                      }}
                      onClick={() => handleBuyNow(product.id)}
                    >
                      {TEXT_MESSAGES.BUYNOW}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <h2
          style={{
            fontSize: "20px",
            fontWeight: 700,
            color: "#181D8C",
            marginLeft: "10px",
          }}
        >
          {TEXT_MESSAGES.ALLSERVICE}
        </h2>
        <Grid container spacing={2} sx={{ marginLeft: "20px" }}>
          {filteredServices?.map((service: Product) => (
            <Grid item xs={12} sm={4} key={service.id}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "400px",
                  height: "215px",
                  borderRadius: "10px",
                  padding: "10px",
                  boxShadow: "2px 2px 10px 0px #6A5FBE26;",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {service.image && (
                    <CardMedia
                      component="img"
                      style={{
                        width: "25px",
                        height: "25px",
                        marginRight: "8px",
                      }}
                      image={service.image}
                      alt={service.name}
                    />
                  )}

                  <Typography
                    component="div"
                    style={{
                      fontWeight: 700,
                      fontSize: "16px",
                      color: "#181D8C",
                    }}
                  >
                    {service.name}
                  </Typography>
                </div>

                <CardContent
                  sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "14px",
                      fontFamily: "inter",
                      color: "#000000",
                      lineHeight: "18px",
                      maxHeight: "120px",
                      overflow: "auto",
                      paddingRight: "4px",
                    }}
                  >
                    {service.description}
                  </Typography>

                  <Box
                    sx={{
                      marginTop: "auto",
                      display: "flex",
                      justifyContent: "flex-start",
                      paddingTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#7A80FF",
                        color: "#ffffff",
                        fontSize: "12px",
                        width: "100px",
                        height: "22px",
                        textTransform: "none",
                      }}
                      onClick={() => handleViewService(service)}
                    >
                      {TEXT_MESSAGES.DETAILVIEW}
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <PheonixSnackBar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={succMessage}
        vertical="top"
        horizontal="right"
      />
    </>
  );
};

export default ProductGridView;
