import React, { useState, useEffect } from "react";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  TextField,
  Card,
  Autocomplete,
  FormControl,
} from "@mui/material";
import { PheonixAutocomplete, PheonixTextfield } from "pheonixui";
import { ProductInterface } from "./ProductTableprops";
import PheonixProductTable from "./ProductTable";

const currencyOptions = [
  { value: "USD", label: "USD" },
  { value: "INR", label: "INR" },
  { value: "EUR", label: "EUR" },
];

const ProductDetails: React.FC<ProductInterface> = ({
  columns,
  rows,
  view,
  edit,
  handleInputChange,
  fieldErrors,
  setFieldErrors,
  ProductReq,
  currency,
  setCurrency,
  formErrors,
  setFormErrors,
  setRows,
  productsData,
  productPlans,
  setProductPlans,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [status, setStatus] = useState(null);
  const [formData, setFormData] = useState({
    domainName: "",
    serverSelection: "",
    username: "",
    password: "",
  });

  const onCurrencyChange = (event: any, newValue: any) => {
    const newCurrency = newValue.value;
    setCurrency(newCurrency);

    if (formErrors["currency"]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        currency: "",
      }));
    }
  };

  useEffect(() => {
    if (currency && rows.length > 0) {
      const updatedRows = rows.map((row) => {
        if (
          row["Product Details"] &&
          row["Product Plan"] &&
          row["Billing Cycle"]
        ) {
          const selectedProduct = productsData.find(
            (product) => product["Product Name"] === row["Product Details"]
          );

          if (selectedProduct && selectedProduct["Product Plan"]) {
            const planName = row["Product Plan"];
            const selectedPlan = selectedProduct["Product Plan"].find(
              (plan) => plan.name === planName
            );

            if (selectedPlan && selectedPlan.pricing) {
              const pricing = selectedPlan.pricing.find(
                (price) => price.currency === currency
              );

              if (pricing) {
                const billingCycleMap: { [key: string]: keyof typeof pricing } =
                  {
                    Quarterly: "quarterly",
                    "Semi Annually": "semiAnnually",
                    Annually: "annually",
                    Biennially: "biAnnually",
                    Triennially: "triAnnually",
                  };

                const billingCycle = row["Billing Cycle"] as string;
                const billingCycleKey = billingCycleMap[billingCycle];
                let newRate = 0;

                if (billingCycleKey && billingCycleKey in pricing) {
                  newRate = pricing[billingCycleKey] as number;
                }

                const quantity = parseFloat(row.Quantity.toString()) || 0;
                const amount = newRate * quantity;

                return {
                  ...row,
                  Rate: newRate,
                  Amount: amount,
                };
              }
            }
          }
        }

        return row;
      });

      setRows(updatedRows);
    }
  }, [currency]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        px: 3,
        py: 2,
        gap: 3,
        maxHeight: "50vh",
        overflowY: "auto",
        overflowX: "hidden",
        width: "95%",
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontFamily: "Inter",
          fontSize: "18px",
          color: "#181D8C",
        }}
      >
        Product Details
      </Typography>

      <FormControl sx={{ width: "309px" }}>
        <Autocomplete
          disableClearable={true}
          options={currencyOptions}
          getOptionLabel={(option) => option.label}
          value={
            currencyOptions.find((option) => option.value === currency) ||
            undefined
          }
          onChange={(event, newValue) => {
            onCurrencyChange(event, newValue);
            if (newValue) {
              setFormErrors((prevErrors) => ({
                ...prevErrors,
                currency: "",
              }));
            }
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={Boolean(formErrors["currency"])}
              helperText={formErrors["currency"] || ""}
              label={
                <>
                  <span>Select Currency</span>
                </>
              }
              size="small"
            />
          )}
        />
      </FormControl>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{ width: "100%", backgroundColor: "#EAEBFF", padding: "10px" }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontFamily: "Inter",
              fontSize: "16px",
              color: "#2C2B2B",
            }}
          >
            Products Details
          </Typography>
        </Box>
        <Card
          sx={{
            width: "100%",
            minHeight: "20vh",
            overflow: "auto",
            p: 2,
          }}
        >
          <PheonixProductTable
            rows={rows}
            columns={columns}
            handleInputChange={handleInputChange}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            ProductReq={ProductReq}
            productsData={productsData}
            currency={currency}
            setCurrency={setCurrency}
            setFormErrors={setFormErrors}
            productPlans={productPlans}
            setProductPlans={setProductPlans}
          />
        </Card>
      </Box>
    </Box>
  );
};

export default ProductDetails;
