import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixProductTableProps from "./ProductTableprops";
import { GET_ALL_PRODUCTS_AND_SERVICES } from "../../graphql/query";
import { api } from "../../api";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Autocomplete,
  TextField,
  Tooltip,
} from "@mui/material";

const planOptions = ["GST 18%", "Non-Taxable"];
const productPlanOptions = ["Basic", "Medium", "Premium", "Pro", "Custom"];
const billCycleOptions = [
  "Quarterly",
  "Semi Annually",
  "Annually",
  "Biennially",
  "Triennially",
];

interface ProductOption {
  label: string;
  value: string;
  id: string;
  fullData: any;
}

const PheonixProductTable: React.FC<PheonixProductTableProps> = ({
  columns,
  rows,
  view,
  edit,
  handleInputChange,
  fieldErrors,
  setFieldErrors,
  ProductReq,
  currency,
  setCurrency,
  setFormErrors,
  productPlans,
  setProductPlans,
}) => {
  const [allProductsData, setAllProductsData] = useState<any[]>([]);
  const [productOptionsAPI, setProductOptions] = useState<ProductOption[]>([]);

  const [error, setError] = useState("");
  useEffect(() => {
    fetchProducts();
  }, []);
  const clearFieldError = (index: number, fieldName: string) => {
    setFieldErrors((prevErrors: { [key: string]: any }) => {
      const updatedErrors = { ...prevErrors };
      if (updatedErrors[index]) {
        delete updatedErrors[index][fieldName];
        if (Object.keys(updatedErrors[index]).length === 0) {
          delete updatedErrors[index];
        }
      }
      return updatedErrors;
    });
  };

  const fetchProducts = async () => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(
        api.baseUrl,
        {
          query: GET_ALL_PRODUCTS_AND_SERVICES,
        },
        config
      );
      const products = response.data.data.getAllProductsServices;
      setAllProductsData(products);

      const mappedProductOptions: ProductOption[] = products.map(
        (product: any) => ({
          label: product.name,
          value: product.name,
          id: product._id || product.id,
          fullData: product,
        })
      );

      setProductOptions(mappedProductOptions);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={column.field}
                sx={{
                  width: index < 3 ? "120px" : "100px",
                  padding: "8px",
                  textAlign: column.field === "Rate" ? "right" : "left",
                  fontWeight: "bold",
                }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {columns.map((column, colIndex) => (
                <TableCell
                  key={column.field}
                  sx={{
                    width: colIndex < 3 ? "120px" : "100px",
                    padding: "8px",
                    textAlign: column.field === "Rate" ? "right" : "left",
                  }}
                >
                  {column.field === "Product Details" ? (
                    <Tooltip title={row[column.field] || "Select an option"}  placement="right-end">
                      <Autocomplete
                        disableClearable
                        options={productOptionsAPI}
                        key={`product-${index}-${column.field}-${
                          row[column.field]
                        }`}
                        size="small"
                        value={row["Product Details"] || null}
                        getOptionLabel={(option) =>
                          typeof option === "string"
                            ? option
                            : (option as ProductOption).value
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={
                              !row[column.field] ? "Select a product" : ""
                            }
                            error={!!fieldErrors?.[index]?.[column.field]}
                            helperText={
                              fieldErrors?.[index]?.[column.field] || ""
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "16px",
                              },
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (newValue?.value !== "addProduct") {
                            if (!currency) {
                              setFormErrors((prevErrors: any) => ({
                                ...prevErrors,
                                currency:
                                  "Please select a currency before adding products",
                              }));
                            }
                            handleInputChange(
                              index,
                              column.field,
                              newValue.value
                            );
                            const selectedProduct = productOptionsAPI.find(
                              (product) => product.value === newValue.value
                            );
                            if (selectedProduct?.fullData?.plans) {
                              const planOptions =
                                selectedProduct.fullData.plans.map(
                                  (plan: any) => ({
                                    label: plan.name,
                                    value: plan.name,
                                  })
                                );

                              setProductPlans((prevPlans) => ({
                                ...prevPlans,
                                [index]: planOptions,
                              }));
                            }
                          }
                        }}
                        isOptionEqualToValue={(option, value) => {
                          if (!option || !value) return false;
                          return (
                            option.value ===
                            (typeof value === "string" ? value : value.value)
                          );
                        }}
                        fullWidth
                      />
                    </Tooltip>
                  ) : column.field === "Product Plan" ? (
                    <Tooltip title={row[column.field] || "Select an option"}  placement="right-end">
                      <Autocomplete
                        disableClearable
                        options={productPlans[index] || []}
                        size="small"
                        value={row[column.field] || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!fieldErrors?.[index]?.[column.field]}
                            helperText={
                              fieldErrors?.[index]?.[column.field] || ""
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "16px",
                              },
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          clearFieldError(index, column.field);
                          handleInputChange(
                            index,
                            column.field,
                            newValue.value
                          );
                        }}
                        fullWidth
                      />
                    </Tooltip>
                  ) : column.field === "Billing Cycle" ? (
                    <Tooltip title={row[column.field] || "Select an option"}  placement="right-end">
                      <Autocomplete
                        disableClearable
                        options={billCycleOptions}
                        size="small"
                        value={row[column.field] || null}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!fieldErrors?.[index]?.[column.field]}
                            helperText={
                              fieldErrors?.[index]?.[column.field] || ""
                            }
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "16px",
                              },
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          clearFieldError(index, column.field);
                          handleInputChange(index, column.field, newValue);
                        }}
                        fullWidth
                      />
                    </Tooltip>
                  ) : column.field === "Quantity" ? (
                    <TextField
                      type="number"
                      size="small"
                      value={row[column.field] || ""}
                      error={!!fieldErrors?.[index]?.[column.field]}
                      helperText={fieldErrors?.[index]?.[column.field] || ""}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleInputChange(index, column.field, e.target.value)
                      }
                      fullWidth
                    />
                  ) : (
                    row[column.field]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PheonixProductTable;
