import React, { useState } from "react";
import {
  Tabs,
  Typography,
  Tab,
  Button,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { TEXT_MESSAGES } from "../../const";
import PheonixBreadcrumbs from "../../Components/PheonixBreadcrumbs";
import PheonixPaper from "../../Components/PheonixPaper";
import Profilepage from "./Profilepage";
import arrow from "../../assets/arow.png";
import MyCart from "./myCart";

const ProfileTab: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(
    parseInt(localStorage.getItem("selectedTab") || "0")
  );
  const breadcrumbTexts = ["Profile", "Add to cart"];
  const navigate = useNavigate();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    localStorage.setItem("selectedTab", newValue.toString());
  };

  const handleBack = () => {
    navigate("/solutions");
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "80vh",
        padding: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        <div>
          <Typography
            style={{
              fontSize: "18px",
              fontFamily: "Roboto",
              color: "#181D8C",
              fontWeight: 600,
            }}
          >
            Profile
          </Typography>
          <PheonixBreadcrumbs breadcrumbText={breadcrumbTexts[selectedTab]} />
        </div>
        <div style={{ display: "flex", height: "35px" }}>
          <Button
            onClick={handleBack}
            sx={{
              color: "white",
              border: "1px solid",
              backgroundColor: "#181D8C",
              width: "218px",
              borderRadius: "5px",
              marginRight: "20px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: 500,
              "&:hover": {
                backgroundColor: "#181D8C",
                color: "white",
                border: "1px solid",
              },
            }}
          >
            {TEXT_MESSAGES.BACK_TEXT}
          </Button>
        </div>
      </div>
      <Box style={{ borderRadius: "5px" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="tabs"
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            style: { backgroundColor: "#181D8C", height: "3px" },
          }}
          sx={{
            width: "auto",
            minWidth: "200px",
            padding: "30px",
            "& .MuiTab-root": {
              minWidth: "100px",
              padding: "6px 12px",
            },
          }}
        >
          {["Profile", "My Cart"].map((label, index) => (
            <Tab
              key={label}
              label={label}
              sx={{
                color: "#00000099",
                fontSize: "14px",
                fontWeight: "500",
                textTransform: "none",
                "&.Mui-selected": {
                  color: "#181D8C",
                  backgroundColor: "#EAEBFF",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                },
              }}
            />
          ))}
        </Tabs>
        <PheonixPaper style={{ height: "70vh" }}>
          {selectedTab === 0 && <Profilepage />}
          {selectedTab === 1 && <MyCart />}
        </PheonixPaper>
      </Box>
    </div>
  );
};

export default ProfileTab;
